@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
$mainfont : 'Nunito', sans-serif;
$fontmontserrat: 'Montserrat', sans-serif;
$fontLato: 'Lato', sans-serif;

.builder-container {
  & { padding: 0; }
  h1 { margin: 0; padding: 0 15px 15px; }
  
  .builder {
    & { display: flex; justify-content: flex-end; }

    .parent-desktop-container { 
      & { max-width: 1400px; width: 90%; margin: auto; }
    }

    .parent-vertical-container {
      & { width: 640px; height: 900px; border: 16px solid #1F1F1F; border-radius: 24px; margin: 50px auto; margin-bottom: 150px; }
      .device-container { width: 100%; height: 865px; overflow: auto; }
      .mobile-upper-bar {
        & { color: #fff; display: flex; align-items: center; justify-content: right; padding: 5px; background: #1F1F1F; gap: 2px; }
        .MuiSvgIcon-root { font-size: 1.2em; }
        .time-container { font-size: 18px; font-weight: 600; }
      }
    }
    .parent-horizontal-container { 
      & { width: 1024px; height: 640px; border: 16px solid #1F1F1F; border-radius: 24px; margin: 50px auto; margin-bottom: 150px; }
      .device-container { width: 100%; height: 615px; overflow: auto; }
      .mobile-upper-bar {
        & { color: #fff; display: flex; align-items: center; justify-content: right; padding: 5px; background: #1F1F1F; gap: 2px; }
        .MuiSvgIcon-root { font-size: 1.2em; }
        .time-container { font-size: 18px; font-weight: 600; }
      }
    }
    .parent-mobile-container { 
      & { width: 360px; height: 700px; border: 16px solid #1F1F1F; border-radius: 24px; margin: 50px auto; margin-bottom: 150px; }
      .device-container { width: 100%; height: 675px; overflow: auto; }
      .mobile-upper-bar {
        & { color: #fff; display: flex; align-items: center; justify-content: right; padding: 3px; background: #1F1F1F; gap: 2px; }
        .MuiSvgIcon-root { font-size: 0.8em; }
        .time-container { font-size: 13px; font-weight: 600; }
      }
    }
    
    
    
    .tools { 
      & { width: 350px; height: 100vh; background: #FFFFFF; position: fixed; left: 0; }
      .widget-heading { & { display: flex; align-items: center; justify-content: space-between; padding: 10px; color: white; background: #212a42; position: relative; }
        .new-style-property-popup { 
          & { box-shadow: 0 0 17px rgba(0, 0, 0, 0.088431); position: absolute; border-radius: 5px; padding: 10px; z-index: 2; bottom: -107px; right: 21px; background-color: #fff; }
          ul {
            & { padding: 0; position: relative; }
            li { cursor: pointer; list-style: none; font-size: 16px; font-family: $fontmontserrat; color: #212121; text-transform: capitalize; text-wrap: nowrap; text-align: right; margin: 14px 0; }
            .arrow {
              & { width: 50px; height: 25px; position: absolute; right: -19px; top: -48px; transform: rotate(180deg); overflow: hidden; }
              &::after { content: ""; position: absolute; width: 12px; height: 20px; background: white; transform: translateX(-50%) translateY(-50%) rotate(45deg); top: 0px; left: 60%; box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0784313725); }
            }
          }
        }
        .add-settings-icon-container { & { display: flex; align-items: center; position: relative; }
          .settings-icon { color: white; padding: 0; min-width: 0; }
          .question-builder-icon { color: white; }
          .add-icon { height: 24px; color: white; }
          .header-confirmation { & { cursor: pointer; width: 199px; position: absolute; border-radius: 5px; padding: 0 10px; z-index: 2; top: 45px; right: 54px; background-color: #fff; color: #000; box-shadow: 0 0 17px rgba(0, 0, 0, 0.088431); font-weight: 600; }
            .arrow-container { & { position: relative; }
              .arrow { position: absolute; width: 15px; height: 15px; background: #ffffff; right: 4px; transform: rotate(45deg); top: -8px; }
            }
          }
        }
      }
      .back-button {
        & { background: #fff; color: #122845; width: 100%; height: 60px; display: flex; }
        button { width: 100%; }
        .left-arrow-icon-dashboard-text { display: flex; align-items: center; }
        .settings-icon { width: 10px; color: #000; }
      }
      .device-view-toggle-options-container {
        & { display: flex; }
        button {
          & { width: 100%; padding: 15px 0; border: none; background-color: #E6E6E6; cursor: pointer; }
          p { font-size: 12px; text-wrap: nowrap; margin-bottom: 0; }
        }
      }
      .tools-container { 
        & { padding: 10px; height: calc(100vh - 244.66px); overflow-y: scroll; }
        .dynamic-tools-container {
          .styling-text { color: #122845; font-size: 15px; font-weight: 600; font-family: "Montserrat", sans-serif; }
          button.add-styles-btn { display: flex; align-items: center; font-size: 14px; font-weight: 700; background: transparent; border: 1px solid; padding: 7px 10px; gap: 8px; }
          .MuiTabPanel-root { & { padding: 0; }
            .add-text-container {
              & { width: 100%; display: flex; justify-content: space-between; box-sizing: border-box; margin: 20px 0; }
              input { border-radius: none; }
              button {  height: 36px; background: #212A42; color: #fff; border-radius: 5px; font-family: "Montserrat"; font-style: normal; font-weight: 700; padding: 10px 20px; }
            }
            button.add-image-btn { margin: 30px 0 0 20px; width: 180px; height: 36px; background: #212A42; color: #fff; border-radius: 5px; font-family: "Montserrat"; font-style: normal; font-weight: 700; }
          }
          .MuiTabs-flexContainer { 
            & { display: flex; justify-content: center; gap: 24px; }
            .MuiTab-root { background-color: transparent; }
            .MuiTabs-indicator { left: 43.0417px; }      
          }
          .add-styles-btn-container {
            & { width: 100%; display: flex; justify-content: center; flex-direction: column; background: #E8E8E8; padding: 20px 5px; gap: 21px; box-sizing: border-box; }
            .current-device-view {
              & { display: flex; align-items: center; gap: 8px; }
              span { font-size: 16px; font-weight: 700; color: black; }
            }
            .add-styles-btn { display: flex; flex-direction: column; border: none; color: #707070; }
          }
        }
        .headings-settings-container { 
          & { display: flex; align-items: center; justify-content: space-between; padding: 10px; }
        }
      }
      .question-builder-and-settings-container {
        & { position: absolute; bottom: 0; width: 100%; padding: 10px; background-color: white; box-sizing: border-box; }
        .question-builder-container { 
          & { display: flex; position: relative;  align-items: center; justify-content: space-between; width: 100%; }
          .question-builder-drawer-btn { background: #122845; color: #fff; border-radius: 4px; font-weight: 600; font-family: $fontmontserrat; }
          .new-style-property-popup { 
            & { box-shadow: 0 0 17px rgba(0, 0, 0, 0.088431); position: absolute; border-radius: 5px; padding: 10px; bottom: 52px; right: 8px; background-color: #fff; }
            ul {
              & { padding: 0; position: relative; }
              li { cursor: pointer; list-style: none; font-size: 16px; font-family: $fontmontserrat; color: #212121; text-transform: capitalize; text-wrap: nowrap; text-align: right; margin: 14px 0; }
              .arrow {
                & { width: 50px; height: 25px; position: absolute; left: 89%; bottom: -94%; transform: translateX(-50%); overflow: hidden; }
                &::after { content: ""; position: absolute; width: 20px; height: 20px; background: white; transform: translateX(-50%) translateY(-50%) rotate(45deg); top: 0px; left: 50%; box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0784313725); }
              }
            }
          }
          .create-new-style-modal {
            & { color: #000; }
          }
        }
      }
      .tools-container::-webkit-scrollbar { width: 5px; }
      .tools-container::-webkit-scrollbar-thumb { background-color: rgb(230, 230, 230); }
      ul.unstyled { list-style: none; padding: 0; }
      .default-tools-widget { & { display: flex; flex-direction: column; justify-content: center; padding: 15px 0; }
        .info { & { min-height: 35vh; display: flex; justify-content: center; align-items: center; }
          p { text-align: center; color: #7F8B9A; font-size: 18px; font-weight: 700; }
          .info-icon { height: 36px; width: auto; }
        }
        .question-builder { & { display: flex; flex-direction: column; justify-content: center; align-items: center; }
          .question-builder-btn { & { font-family: $fontmontserrat; padding: 15px 30px; font-size: 18px; font-weight: 700; color: #7F8B9A; line-height: 21px; text-align: left; box-shadow: 5px 5px 7px #0000002E; border: 1px solid #DDDDDD; border-radius: 8px; }
            img { height: 40px; width: auto; padding: 0 10px; }
          }
          p { color: #99A2AF; max-width: 16ch; text-align: center; font-size: 18px; }
        }
       .navigator-btn { background: #DDF4FA; font-weight: 600; box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0705882353); border-radius: 5px; height: 40px; margin: 0; text-transform: capitalize; font-size: 16px; color: #000; gap: 7px; }
      }
      .tools-container {
        .crop-container { & { height: 450px; } 
          .crop-btns { & { justify-content: center; }
            .slider { margin: 0 auto; }
            h3 { text-align: center; }
          }
        }
        .editable-text-div { width: 95%; border: 1px solid #707070; height: 43px; display: flex; align-items: center; padding: 5px; box-sizing: border-box; padding-top: 9px; }
        .tool { & { padding: 10px 8px; }
          .suggestion-box {
            & {  display: flex; flex-direction: column; position: absolute; background: #fff; z-index: 1; width: 85%; box-shadow: 0 12px 20px #d7d7d7; padding: 0px; }
            span { padding: 10px; cursor: pointer; }
            span:hover { background-color: #d7d7d7; }
          }
          .styling-text-delete-icon-container { & { display: flex; align-items: center; gap: 20px; } 
            .delete-icon { display: flex; align-items: center; color: #122845; cursor: pointer; }
          }
          .input-and-unit-container { & { display: flex; align-items: center; } }
        }
        .styling-text-delete-icon-container { display: flex; align-items: center; }
        .padding-input-container { 
          & { display: flex; align-items: center; }
          .padding-input {
            .input-heading { padding-bottom: 5px; }
            .input-container {
              & { display: flex; align-items: center; }
              input { padding: 0; text-align: center; height: 41px; max-height: 41px; background-color: #FFFFFF; border: 1px solid #707070; width: 40px; }
            .styling-select {
              & { width: 38px; padding: 0; height: 41px; max-height: 41px; background-color: #FFFFFF; border: 1px solid #707070; display: flex; justify-content: center; align-items: center; }
              .MuiSelect-select { padding-left: 5px; font-size: 12px; text-transform: lowercase; padding-right: 10px; }
            }
            }
          }
        } 
        .margin-input-container { 
          & { display: flex; align-items: center; }
          .margin-input {
            .input-heading { padding-bottom: 5px; }
            .input-container {
              & { display: flex; align-items: center; }
              input { padding: 0; text-align: center; height: 41px; max-height: 41px; background-color: #FFFFFF; border: 1px solid #707070; width: 40px; }
            .styling-select {
              & { width: 38px; padding: 0; height: 41px; max-height: 41px; background-color: #FFFFFF; border: 1px solid #707070; display: flex; justify-content: center; align-items: center; }
              .MuiSelect-select { padding-left: 5px; font-size: 12px; text-transform: lowercase; padding-right: 10px; }
            }
            }
          }
        } 
        .add-default-styles-btn {
          & { display: flex; align-items: center; width: 100%; background-color: white; flex-direction: column; justify-content: space-between; margin: 6px 0 0 7px; color: #707070; border-radius: 5px; font-family: $fontmontserrat; font-weight: 600; border: none; }
        }
        .display.tool {
          .styling-select { width: 114px; }
        }
        .flex-direction.tool { 
          .styling-select { width: 98%; }
        }
        .justify-content.tool{ 
          .styling-select { width: 98%; }
        }
        .align-items.tool{ 
          .styling-select { width: 98%; }
        }
        .positioning.tool {
          p.styling-text.dynamic-styling-text { display: none; }
          .object-spacing {
            .delete-icon { display: none; }
          }
        }
        .tool .styling-text { & { color: #122845; font-size: 15px; font-weight: 600; font-family: $fontmontserrat; text-wrap: nowrap; }}
        .height, .width { display: inline-block; width: 45%; }
        .object-spacing, .vertical-alignment { display: inline-block; width: 47.5%; }
        .backgroundimg { & { display: inline-block; width: 45%; }
          .styling-select { width: 98%; }
        }
        .vertical-alignment { padding-left: 5%; }
        .styling-input { & { width: 8ch; }
          input { padding: 10px 20px; background-color: #FFFFFF; border: 1px solid #707070; outline: none; box-shadow: none; }
          input:hover { box-shadow: none; }
          .MuiFilledInput-root { height: 43px; }
        }
  
        // div.blurred div.ql-toolbar { display: none; }
  
        .styling-input { & { width: 41px; padding: 0; height: 43px; max-height: 43px; }
          input { padding: 0px 5px; height: 41px; }
          .MuiFilledInput-root.MuiFilledInput-underline::before { border-bottom: none; }
        }
        .bgcolor-input { width: 80%; }
        .height-input { width: 65%; }
        .width-input { width: 65%; }
        .styling-select { & { width: 37px; padding: 0; height: 41px; max-height: 41px; background-color: #FFFFFF; border: 1px solid #707070; }
          .MuiInputBase-root { height: 100%; border-radius: 0; font-family: $mainfont; color: #5e5e5e; }
          .MuiSelect-select { padding-left: 8px; padding-right: 16px; padding-left: 5px; padding-right: 10px; }
          .MuiSelect-icon { right: 2px; right: -2px; }
          // input { padding: 8px; }
        }
        .font-size {
          .styling-input { width: 60px; }
        }
        .font-weight {
          .styling-select { width: 60px; }
        }
        .box-sizing-select { width: 33%; }
        .fontweight-unit { & { width: 55px; }
          .MuiSelect-select { padding-left: 10px; padding-right: 10px; }
        }
        .textalign-unit { & { width: 90px; }
          .MuiSelect-select { padding-left: 10px; padding-right: 10px; }
        }
        .justifycontent-unit, .alignitems-unit { & { width: 98%; }
        .MuiSelect-select { text-transform: capitalize; font-size: 14px; }
        }
  
  
        .padding-section, .margin-section { & { display: inline-block; width: 25%; }}
        .font-styles { & { display: flex; gap: 20px; }
          .font-size-container {
            p.styling-text.dynamic-styling-text { display: none; }
            .delete-icon { display: none; }
          }
        }
        p { margin: 10px 0 5px 0; }
        p.subheaders { font-size: 12px; text-transform: uppercase; }
        input { padding: 5px; font-size: 14px; color: #5e5e5e; }
        input[type="color"] { padding: 0; outline: none; border: 1px solid #333333; cursor: pointer; height: 50px; width: 55px; margin-left: 5px; margin-top: -4px; border: none; border-color: transparent; }
        input[type="color"].color-box { width: 45px; }
        input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { margin: 0; padding: 0; margin-right: -5px; }
        .MuiSelect-select { padding-top: 5px; padding-bottom: 5px; font-size: 12px; max-height: 20px; text-transform: uppercase; }
        .MuiTabs-flexContainer { 
          .MuiTab-root { color: #313131; font-weight: 700; font-family: $fontmontserrat; }
          .MuiTab-root.Mui-selected { background-color: #ffffff; }
          .MuiTab-root { background-color: #dfdfdf; }
        }
        .dropzone-content { & { text-align: center; }
          p { font-size: 14px; font-family: $fontmontserrat; font-weight: 600; text-transform: uppercase; }
          p:first-child { font-weight: 700; color: #313131; }
        }
        .easy-crop {
          .d-n-d { 
            & { padding: 5px 10px; height: auto; width: 90%; border: 2px dashed #92929292; border-radius: 5px; }
            .MuiButtonBase-root { padding: 5px 10px; }
          }
          .reactEasyCrop_Container {
            & { height: 100%; border-radius: 10px; }
          }
        }
      }
      .image-widget-tabs {
        .MuiBox-root {
          .MuiTabs-flexContainer { & { background-color: #122845; }
            .MuiTab-root.Mui-selected { color: #9B9B9B; background-color: #E6E6E6; }
            .MuiTab-root { & { color: #fff; } }
          }
        }
      }
    }

    .build {
      & { width: calc(100% - 350px); height: 100vh; border-left: 1px solid #efefef; overflow: hidden; background: #757575; }
      .editable-text[contenteditable="true"] { outline: 1px solid transparent; padding: 5px 10px; }
      .editable-text[contenteditable="true"]:focus { outline: 1px solid #7FB6BE; }
      .start { display: flex; width: 100%; font-family: $fontLato; }
      .info-section { 
        & { display: flex; max-width: 1100px; margin: 0 auto; border-radius: 10px; background-color: #ffffff; box-shadow: 0 1px 20px rgb(0 0 0 / 10%); font-family: $fontLato; }
        .assessment-container { width: 100%; position: relative; z-index: 1; }
        .results-container { 
          & { position: relative; }
          .invite-team-add-section-container { & { border-radius: 8px; margin-top: 87px; background: white; padding: 20px; }
            .invite-team-content { 
              & { display: flex; align-items: center; justify-content: center; border: 4px dashed #d0d0d0; border-radius: 26px; min-height: 380px; }
              button { 
                & { display: flex; align-items: center; background-color: #212A42; color: white; padding: 14px 35.1px; font-size: 23px; border-radius: 13px; cursor: pointer; }
                svg.MuiSvgIcon-root { font-size: 2rem; }
              }
              img { width: 80px; }
            }
            h1 { font-size: 18px; color: #909090; font-family: $fontLato; }
          }

          .testimonials-add-section-container { & { border-radius: 8px; margin-top: 87px; background: white; padding: 20px; }
            .testimonial-content { 
              & { display: flex; align-items: center; justify-content: center; border: 4px dashed #d0d0d0; border-radius: 26px; min-height: 380px; }
              button { 
                & { display: flex; align-items: center; background-color: #212A42; color: white; padding: 14px 35.1px; font-size: 23px; border-radius: 13px; cursor: pointer; }
                svg.MuiSvgIcon-root { font-size: 2rem; }
              }
            }
            h1 { font-size: 18px; color: #909090; font-family: $fontLato; }
          }

          .sample-dashboard-section-container { & { border-radius: 8px; margin-top: 87px; background: white; padding: 20px; }
            .sample-dashboard-content { 
              & { display: flex; align-items: center; justify-content: center; border-radius: 26px; min-height: 380px; flex-direction: column; }
              button { 
                & { display: flex; align-items: center; background-color: #212A42; color: white; padding: 14px 35.1px; font-size: 23px; border-radius: 13px; cursor: pointer; }
                svg.MuiSvgIcon-root { font-size: 2rem; }
              }
              img { width: 100%; }
            }
            .individual-group {
              & {  display: flex; align-items: center; }
              button { width: 184px; border-radius: 0; display: flex; justify-content: center; }
            }
            h1 { font-size: 18px; color: #909090; font-family: $fontLato; }
          }
        }
        .video-img-left { & { width: 50%; height: 100%; align-self: center; text-align: center; border-radius: 10px 0 0 10px; object-fit: cover; overflow: hidden; position: relative; display: flex;
          justify-content: center; }
          .video { margin: 0 0 0 -50px; }
          .thumbnail { position: absolute; height: 100%; overflow: hidden; }
          .playbtn { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; }
          .upload { & { border: 4px dashed #77777777; padding: 15px; margin: 15px; width: 100%; border-radius: 15px; display: flex; justify-content: flex-end; flex-direction: column; align-items: center; }
            .add-icon { color: #777777; font-size: 46px; }
            .image-or-remove { & { display: flex; flex-direction: row; height: 40%; width: 100%; }
              button { align-self: flex-end; width: 100%; width: auto; font-family: $fontLato; font-size: 16px; padding: 10px; font-weight: 600; text-transform: capitalize; margin: 0 auto; padding: 4px 8px; z-index: 1; background-color: #FFFFFF; border: 2px solid dodgerblue; color: dodgerblue; }
            }
          }
        }
        // .text-right { 
        //   & { font-family: $fontLato; flex: 1 1 42%; width: 42%; padding: 4%; border-radius: 0 10px 10px 0; display: flex; flex-direction: column; align-items: center; }
        //   // h1 { font-size: 42px; font-weight: 700; line-height: 58px; margin: 0 0 38px; width: auto; text-align: left; }
        //   // h2 { font-size: 24px; font-weight: 300; line-height: 27px; max-width: 28ch;  text-align: left; margin: 0; }
        //   // p { font-size: 24px; }
        //   // .get-started-cta { background-color: #313131; color: #FFFFFF; font-weight: 600; padding: 8px 18px; font-size: 18px; margin: 30px auto 0 0; }
        // }
        .section-names { 
          h3 { margin: 10px auto 10px; cursor: default; }
        }

        .question-container { & { justify-content: center; flex-direction: column; align-self: center; width: 85%; max-width: 90%; min-height: 280px; }
          .question { min-height: 120px; }
        }

        .radio-container { & { width: 86%; margin: 0 auto; }
          .slider-labels { & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; display: flex; justify-content: space-between; }
            .label { & { cursor: pointer; width: 20%; float: left; position: relative; }
              p { display: block; position: absolute; margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; } // margin-left: -50%;
              p.desktop { display: block; }
              p.mobile { display: none; }
            }
            .active { font-weight: 700; color: rgb(62, 133, 199); }
          }
          .MuiRadio-root {  } // margin-left: -20px;
        }
  
        .slider-container { & { width: 86%; margin: 0 auto; margin: 0px auto; padding: 75px 15px; }
          .slider { width: 100%; position: relative; }
          .slider-points { & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
            .point { & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }}
          }
          .slider-labels { & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
            .label { & { cursor: pointer; width: 20%; float: left; position: relative; }
              p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
              p.desktop { display: block; }
              p.mobile { display: none; }
            }
            .active { font-weight: 700; color: #3A86C0; }
          }
  
          .custom-slider.MuiSlider-root { z-index: 1000; color: #3A86C0; width: calc(100% + 10px); margin-left: -5px; }
          .custom-slider .MuiSlider-rail { opacity: 0; background-color: #3A86C0; height: 10px; }
          .custom-slider .MuiSlider-track { border: 0px solid #3A86C0; background-color: #3A86C0; height: 14px; }
          .custom-slider .MuiSlider-thumb { background-color: #3A86C0; width: 22px; height: 22px; }
          .custom-slider .MuiSlider-thumb:before { background-color: #3A86C0; border: 3px solid #fff; width: 10px; height: 10px; }
          .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
          .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
          .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
          .custom-bar .MuiLinearProgress-bar { background-color: #3A86C0; top: -1px; }
        }
        .step-buttons {
          & { display: flex; justify-content: space-between; }
          button { 
            &{ font-weight: 700; color: #000; display: flex; align-items: center; gap: 10px; }
            img { width: 30px; }
          }
        }
        .add-questions-btn-container-parent {
          & { position: absolute; top: 40%; display: flex; justify-content: space-between; width: 110%; left: -5%; z-index: -1; }
          .add-questions-buttons-container { & { display: flex; justify-content: space-between;  width: 120%; } 
            .add-button {
              & { border: 2px dashed #707070; padding: 40px 10px; background: #FFFFFF; opacity: 0.33; border-radius: 0; }
              svg { width: 1.5em; height: 1.5em; }
            }
          }
        }
      }
      .builder-loading-spinner { height: 100%; display: flex; justify-content: center; align-items: center; }
      
      .website-container {
        
        .website { & { height: 100vh; }
          .builder-loading-spinner {
            & { display: flex; justify-content: center; align-items: center; height: 100%; }
          }
        }
        
        header {
          .header-wrapper { display: flex; height: 125px; background-color: #fff; font-family: $fontLato; } // height: 125px; 
          .logo-text {
            p { margin: 0 auto; padding: 15px 10px; }
          }
        }

        

        .image-container img { width: 100%; }
        
        .results-page {
          .results-header h2 { font-family: $fontmontserrat; font-size: 30px; }
          .results-text h4 { font-family: $fontmontserrat; font-size: 18px; }
        }
      }
    }

    // .details {
    //   * { box-sizing: border-box; }
    //   & { width: 100%; }
    .details-form { 
      & { display: inline-block; justify-content: center; align-items: center; flex-direction: column; margin: 0 auto; width: 100%; row-gap: 2%; text-align: left; }
      .input-field { & { padding: 7px 15px; }
        p { margin: 0; font-weight: bold; font-size: 1.3rem; } 
        .input-edit-button {background-color: #eee; height: 50px; padding: 15px; border-radius: 3px; }
        // .MuiFormControl-root {width: 400px;}
      }
      .input-section { & { width: 100%; display: flex; justify-content: space-between; align-items: baseline; } 
      .input-delete-button { background: transparent; width: 30px; height: 30px; border: none; cursor: pointer; }  
      .input-delete-button svg { fill: #5d4f4f; }
      .input-edit-button { background: transparent; width: 30px; height: 30px; border: none; cursor: pointer; margin-right: 10px; }
    }
      .input-field.full-width { & { width: 100%; }
        .form-details-text { width: 100%;}
        //  .MuiInput-root { background-color: #F2F2F2; }
        //  .MuiInput-input { height: 56px; padding-inline-start: 10px; }
      }
      .input-field.half-width { & { width: 46%; display: inline-block; }
        .form-details-text { & { width: 100%; margin-top: 10px; }
          .MuiInput-root { background-color: #F2F2F2; }
          .MuiInput-input { height: 56px; padding-inline-start: 10px; }
      }
      }
      .add-input-field-button.MuiButtonBase-root { height: 50px; padding: 15px; border-radius: 3px; border: 2px dashed #bbb; width: 100%; color: #aaa; margin-top: 28px; font-size: 16px; justify-content: start; }
    }
    .submit-button.MuiButtonBase-root { width: 100%; max-width: 280px; padding: 7px 15px; margin: 0 auto; border-radius: 10px; color: #fff; margin-top: 40px; font-size: 20px; background-color: #212A42; text-transform: uppercase; letter-spacing: 1.1px; font-weight: 500; }

    .results-container { 
      .update-scoring { position: absolute; right: 50px; color: #21212177; font-size: 12px; font-weight: 700; top: -31px; padding: 8px; border-top: 1px solid #21212177; border-left: 1px solid #21212177; border-right: 1px solid #21212177; border-top-right-radius: 4px; border-top-left-radius: 4px; cursor: pointer} 
      .update-heading { position: absolute; left: 50%; margin-left: -100px; color: #21212177; font-size: 12px; font-weight: 700; top: 18px; padding: 8px; border: 1px solid #efefef; border-radius: 4px; cursor: pointer} 
    }

    .results, .dashboard {
      .progress-bar { & { margin: 60px auto 5px; max-width: 90%; }
    
        .si_pro_header { max-width: 700px; margin: 10px auto 0; display: flex; clear: both; position: relative; bottom: 88px; }
        .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color: red; text-transform: capitalize; margin-top: 10px; }
        .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; }
        .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
        .si_pro_header h4.eexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
        .si_pro_header h4.eexpe1:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; }
        .si_pro_header h4 span { color: green; line-height: 35px; font-size: 16px; }

        .pervalue h4 { position: relative; }
        .pervalue { max-width: 700px; margin: -68px auto; margin: 0 auto; display: flex; clear: both; color: #4A4A4A; font-size: 15px; position: relative; top: -20px; }
        // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
        // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
        // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
        // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
        .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; font-weight: 800;}
        .pervalue h4.nimp:after { content: "45%"; position: absolute; right: -19px;  font-weight: 800;}
        .pervalue h4.mexpe:after { content: "70%"; position: absolute; right: -19px;  font-weight: 800;}
        .pervalue h4.eexpe:after { content: "100%"; position: absolute; right: -10px; font-weight: 800;}
        .pervalue h4.eexpe1:after { content: "70%"; position: absolute; right: -19px; font-weight: 800;}
        .pervalue h4.eexpe2:after { content: "100%"; position: absolute; right: -19px; font-weight: 800;}
        // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
        // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
        // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
        .pervalue-text { & { position: relative; display: flex; margin: 0 auto; width: 100%; max-width: 700px; bottom: 55px; }
          p { font-family: $fontmontserrat; display: inline-block; text-align: center; margin: 0 auto; top: 12px; font-size: 16px; vertical-align: top; color: #575757; line-height: 16px; }
        }
        in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: $fontmontserrat; }
        .si_ass_progress:before {  }
        .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

        .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
          background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); 
          // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
        }
        .si_ass_progress .progress-line { z-index: 2; width: 2px; height: 40px; content: ' '; background: red; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
        .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: red; height: 30px; text-align: center; }
        .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #4A4A4A; bottom: 38px; left: 0; margin-left: -20px; }
        
        
        // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }

        .pre-score-header { padding: 30px 15px 1.5rem; text-align: center; color: #4A4A4A; font-family: $fontmontserrat; font-size: 24px; font-weight: 600; margin: auto 0; }
        
        .result-sections { 
          & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
        }
      }
    }

    

    .change-page-container {
      & { position: fixed; bottom: 0; display: flex; justify-content: center; width: calc(100vw - 350px); } 
      .change-page { & { min-width: 369px; justify-content: space-between; bottom: 0; display: flex; z-index: 9; background: #FFFFFF; box-shadow: 0px 2px 10px #00000012; border-radius: 12px 12px 0px 0px; height: 40px; }
        button {
          .change-page-icon { font-size: 30px; color: #5b5b5b; }
        }
        .disabled-left {
          svg { color: #cbcbcb; }
        }
        .page-name-counter-container {
          & { display: flex; gap: 20px; align-items: center; padding: 15px 20px; }
          .page-name { margin: 0; text-transform: capitalize; font-size: 18px; color: #57606F; }
          p { margin: 0; font-weight: 600; font-size: 18px; color: #57606F; }
        }
      }
      .navigator-btn { background: #DDF4FA; font-weight: 600; box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0705882353); border-radius: 12px 12px 0px 0px; height: 40px; margin: 0; text-transform: capitalize; font-size: 16px; color: #000; gap: 7px; }
    }

    .builder-page-parent-section {
      & { height: 100vh; overflow-y: scroll; }
      
    }
    
    section.builder-page-parent-section.mobile-version { background-color: #494949; }
  }

}

.drawer.questions { & { padding: 15px; box-sizing: border-box; }
  .question-builder-content { 
    & {display: flex;  justify-content: flex-start; gap: 10px; align-items: center; }
    .variable-input-box {
    .MuiOutlinedInput-input { padding: 0px 35px;}
    }
  }
  .question-builder-heading { text-align: left; font-size: 20px; font-weight: bold; font-family: $fontmontserrat; letter-spacing: 0px; color: #57606F; }
  .sections {
    &{ margin-bottom: 30px; }
    h2 { text-align: left; font: normal normal bold 24px/32px $fontLato; letter-spacing: 0px; color: #57606F; }
    .drawer.add-section { & { width: 100%; max-width: 70vw; box-sizing: border-box; }
      header { padding: 15px 0 0; }
      h1 { font-family: $fontmontserrat; font-size: 1.5em; transition: all 0.1s; } 
      section { padding: 0; width: 100%; }
    }
    .category-box-and-add-category-container {
      & { display: flex; align-items: center; justify-content: space-between; gap: 15px; }
      .category-box {
        & { display: flex; align-items: center; gap: 20px; overflow-x: scroll; }
        .category-box-child {
          & { flex-direction: column; display: flex; justify-content: space-between; padding: 10px 20px; min-width: 202px; height: 104px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000014; border: 1px solid #E6E6E6; border-radius: 8px; opacity: 1; margin: 10px 0; margin-top: 26px; }
          .category-order-name-edit-container {
             & { display: flex; justify-content: space-between; }
             .order-no-and-drag-section-name-icon {
              & { display: flex; align-items: center; gap: 10px; }
              .order-no { font-size: 18px; text-align: left; font: normal normal medium 14px/18px $fontmontserrat; letter-spacing: 0px; color: #0F131D; }
              .order-drag-icon {
                & { font-size: 30px; height: 30px; cursor: grab; }
                img { cursor: grab; }
              }
              .section-name { font-size: 16px; text-align: left; font: normal normal medium 14px/18px $fontmontserrat; letter-spacing: 0px; color: #0F131D; }
             }
             .edit-icon { font-size: 24px; display: flex; align-items: center; }
          }
          .delIcon-calculator-and-questionIcon-container{
            &{ display: flex; justify-content: space-between; align-items: center; }
            .questionIcon-and-calculator {
              & { display: flex; align-items: center; gap: 15px; }
              .data-multiplier { 
                & { font-size: 24px; display: flex; align-items: center; gap: 5px; }
                p { font-size: 18px; }
               }
              .data-calculator {
                & { font-size: 24px; display: flex; align-items: center; gap: 5px; }
                p { font-size: 18px; }
              }
            }
          }
          img { cursor: pointer; }
        }
      }
      .add-section-block {
        & { flex-direction: column; gap: 20px; display: flex; align-items: center; justify-content: center; padding: 10px 0; min-width: 140px; height: 104px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0784313725); border: 2px dashed #9B9B9B; border-radius: 8px; opacity: 1; cursor: pointer; }
        h1 { font: normal normal bold 14px/18px Montserrat; text-align: center; }
        svg { font-size: 24px; }
      }
    }
  }

  .order-and-drag-container { 
    & { display: flex; align-items: center; font-weight: bold; }
    svg { font-size: 2rem; color: #000000; }
  }
  .question-text {
      &{ color: #000; font-weight: bold; }
      .question-text-icon-container {
        &{ display: flex; gap: 10px; width: 455px; }
        .mandatory-question-indicator {
           & { width: 15px; cursor: pointer; margin-top: 2px; }
        }
      }
      .question-text-container {
        & { width: 550px; text-align: left; font: normal normal bold 14px/18px $fontmontserrat; letter-spacing: 0px; color: #0F131D; }
      }
      span{ text-align: left; font: normal normal 600 14px/18px $fontmontserrat;  letter-spacing: 0px; color: #9B9B9B; }
  }
  .addtional-question-row { border-top: 1px solid #E6E6E6; border-bottom: 1px solid #E6E6E6; padding: 8px 25px; }
  .additional-category{
    span{ font-weight: bold; }
  }
  .category-txt{ font-weight: bold; 
    span { text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #0F131D; } }
  .type-txt{ 
    &{ display: flex; align-items: center; gap: 10px; }
      p{ text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #0F131D; }
   }
  .heading-and-btn-container{
    &{ display: flex; align-items: center; justify-content: space-between; }
    .black-btn { text-align: left; font: normal normal bold 16px/21px $fontLato; letter-spacing: 0px; color: #FFFFFF; }
    .heading-icon-container {
      & { display: flex; align-items: center; gap: 8px; }
      .generic-msg-icon { & { width: 32px; height: 32px; border-radius: 100%; display: flex; align-items: center; justify-content: center; background: #57606F; } 
        img { width: 24px; height: 24px; }
      } 
      h2 { text-align: left; font: normal normal bold 24px/32px $fontLato; letter-spacing: 0px; color: #57606F; }
    }
  }

  .additional-question-heading { margin-bottom: 0; }
  
  .variables-container{
    .text-icon-container {
      & { display: flex; align-items: center; gap: 10px; }
      p.text { text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #0F131D; }
      img { cursor: pointer; width: 24px; height: 24px; }
    }
    .ip-and-edit-cta {
      &{ width: 123px; display: flex; gap: 5px; }
      input{ width:50%; }
      button{ padding:5px 10px; width:50%; background:#122845; border-radius: 4px; text-align: left; font: normal normal 500 14px/21px $fontmontserrat; letter-spacing: 0px; color: #FFFFFF; }
    }
  }
  .edit-and-trash-icon {
    &{ display: flex; align-items: center; gap: 8px; font-size: 1.2rem; }
  }
  thead{ background-color: #F1F1F1; }
  
  .logic-icons {
     & { display: flex; gap:40px; }
     img { cursor: pointer; padding: 3px; }
  }
  .variable-input-box {
   & { width: auto; position: relative; padding: 5px 0; }
   .MuiOutlinedInput-input { padding: 0; height: 25px; width: 80px; display: flex; align-items: center; padding-left: 10px; font-size:15px; }
   .variable-input-suggestion-box {
   & { padding: 10px 0; width: 100%; position: absolute; display: flex; flex-direction: column; justify-content: center; }
   .suggestions { padding: 10px 20px; }
   .suggestions:hover { background: rgb(223, 221, 221); }
   }
  }
  .display-section {
   & { display: flex; gap: 10px; }
   span { color:#212121; font-family: $fontmontserrat; font-size: 15px; font-weight: 300; padding: 5px 0; }
     p { color: #212121; font-family: $fontmontserrat; font-size: 15px; font-weight: 300; padding: 5px 0; margin: 0px; }
  }
  .jump-section ,.display-section-two {
   & { display: flex; gap: 10px; }
   span { color: #212121; font-family: $fontmontserrat; font-size: 15px; font-weight: 300; padding: 5px 0; margin-left:-30px; }
  }
  .addmore-btn {
   & { width: 126px; height: 32px; border: 2px solid #122845; border-radius: 4px; color: #122845; font-size: 15px; font-weight: 600; text-transform: capitalize; white-space: nowrap; font-family: $fontmontserrat; }
  }
  .save-btn{
    & { width: 126px; height: 32px; border: none; border-radius: 4px; color: #FFFFFF; background-color: #122845; font-size: 15px; font-weight: 600; text-transform: capitalize; white-space: nowrap; font-family: $fontmontserrat; }
   }
  .jump-container , .display-container{
    & { display: flex; align-items: flex-start; justify-content: space-between; margin-bottom:5px; }
    .jump-btns,.display-btns{
      & { display: flex; align-items: flex-start; gap:10px; margin-top: 5px; }
      img { margin-top: 5px; cursor: pointer; }
    }
  }
  .jump-section { display: flex; align-items: flex-start; gap:20px; position: relative; z-index: 2;  }
  .border-section { border-left: 1px solid #BEBEBE; border-right:1px solid #BEBEBE;  padding:0px 50px 0px 50px ; opacity: 1;  }
  .break-line { border-top: 1px solid #BEBEBE;}
  .extra-margin{ margin-top: 5px;}
  .btn-group { display: flex; flex-direction: column; gap: 10px; }
  .btn-groups {
      & { display: flex; justify-content: end; gap:10px; margin-top: -33px; margin-right: -30px; position: relative; z-index: 1; }
      img { margin-top: 5px; cursor: pointer; }
    }
}

.question-builder-drawer-loading-container { height: 100%; display: flex; align-items: center; justify-content: center; }