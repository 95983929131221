.assessment-name { 
  
  & { background-color: #F4F2EC; font-size: 26px; padding: 15px 30px; margin: 0; display: flex; align-items: center; justify-content: space-between; }
  h1 { margin: 10px auto 0; line-height: 20px; font-family: $fontbutler; letter-spacing: 1px; } 
  .assessment-link-text { font-size: 18px; color: #5B5B5B; margin: 5px auto; }
  .assessment-link { color: #5B5B5B; }
  p.fetch-users-status { font-size: 16px; line-height: 18px; margin: 5px auto; }
  .header-cta { & { display: flex; align-items: center; justify-content: space-between; cursor: pointer; background-color: #212A42; color: #fff; padding: 7px 10px; border-radius: 3px; font-family: $fontmontserrat; font-weight: 600; font-size: 18px; }
    .MuiSvgIcon-root { margin-right: 3px; }
  }
  .header-cta.inactive { color: white; background: #212A42; }
  // .header-cta:hover { background-color: #c6bfbf; color: #fff; }
  .MuiIconButton-root {color: #FFF;  padding: 0px; }
}

.assessment-page, .assessment-dashboard-page { 
  & { padding: 15px 30px; font-family: $fontmontserrat; }
  .tab-container { & { display: flex; flex-direction: row; }
    .box { border-bottom: 1px solid #EDEDED; }
    .total-assessments { position: absolute; right: 4%; text-align: right; width: 28ch; }
    .tab-title { text-transform: capitalize; font-family: $fontmontserrat; font-size: 15px; font-weight: 600; }
    .tab-title.Mui-selected { font-size: 15px; font-weight: 600 !important; }
    .search-bar { & { position: relative; width: 250px; }
      input[type="search"].search-field::-webkit-search-cancel-button { -webkit-appearance: none; }
      .search-field { background-color: #FBF9F3; color: #979797; padding: 10px; font-size: 16px; border: 1px solid #CBCBCB; border-radius: 3px; margin: 0 0 15px 0; font-family: $fontmontserrat; width: 250px; }
      .clear-search-icon { position: absolute; right: 8px; bottom: 22px; cursor: pointer; color: #777777; height: 24px; width: 24px; font-size: 24px; }
    }
  }
  .page-header {
    & { display: flex; align-items: center; justify-content: space-between; }
    h1 { font-weight: 600;font-family: $fontmontserrat; }
    .create-btn { background-color: #212A42; color: #ffffff; padding: 8px 16px; font-size: 16px; font-family: $fontmontserrat; }
  }
  .filter-button { 
    &{ position: relative; margin-top: -15px; margin-left: 20px;}
    .MuiButtonBase-root {height: 40px;}
  }
  .delete-user-icon { cursor: pointer; padding: 5px; }
  .search-and-delete { display: flex; justify-content: flex-start; align-items: center; }
  .users-table div.MuiDataGrid-root {
    // min-height: 500px; height: 100%;
    background-color: #FFFFFF; height: 90vh; width: 100%;
    .MuiDataGrid-cell { & { /* text-transform: capitalize; */ }
    .color-coded-blocks { height: 30px; width: 100%; color: #FFFFFF; text-align: center; font-size: 10px; font-weight: 500; display: flex; justify-content: center; align-items: center; border-radius: 5px; cursor: default; }
  }
    [title="Completed"] {
      color: #8fbb69;
    }
    [title="Pending"] {
      color: #d46060;
    }
  }

  // Members Table or Referrals Table 
  .referrals-table {
    div.MuiDataGrid-root { background-color: #FFFFFF; height: 90vh; width: 100%; }
    .MuiDataGrid-cell { /* text-transform: capitalize; */ }
    
  }
  
  .leaders-table {
    div.MuiDataGrid-root { height: 85vh; width: 100%; background-color: #FFFFFF; }
  }
  
  // Statistics Cards in Leaders & Members Tabs 
  .statistics-cards {
    & { display: grid; grid-template-columns: repeat(6, minmax(0, 1fr)); margin: 0 auto 15px 0; max-width: 1150px; overflow-x: auto; }
    .card { & { background: #FFFFFF; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 5px; padding: 5px; margin: 10px 5px; text-align: center; cursor: pointer; border: 1px solid transparent; }
      .big-text { font-size: 36px; font-weight: 700; color: #000000; margin: 10px 10px; }
      .percent-text { font-size: 18px; font-weight: 600; color: #868686; margin: 10px 10px; }
      .info-text { 
        font-size: 14px; font-weight: 600; color: #494949; margin: 10px 5px;
        .delete-icon-container {
          & { margin-top: 20px; float: right; }
          svg { font-size: 16px; margin-top: 0px; color: rgb(175, 175, 175); }
        }
      }
    }
    .card.active { background-color: #F2D466; border: 1px solid #21212177; 
      // box-shadow: 0 0 5px 1px #21212177; 
    }
  }
  
  .dashboard-table {
    div.MuiDataGrid-root { height: 85vh; width: 100%; background-color: #FFFFFF; }
    .statistics-cards {
      & { display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); margin: 0 auto 15px 0; }
      .card { & { background: #FFFFFF; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 5px; padding: 5px; margin: 10px 5px; text-align: center; }
        .big-text { font-size: 36px; font-weight: 700; color: #000000; margin: 10px 10px; }
        .percent-text { font-size: 18px; font-weight: 600; color: #868686; margin: 10px 10px; }
        .info-text { font-size: 14px; font-weight: 600; color: #494949; margin: 10px 10px; }
      }
    }
  }

  .statistics-dash {
    .MuiBox-root { padding: 0; }
  }

  .MuiDataGrid-cell { font-family: $fontmontserrat; font-size: 14px; }
  .MuiDataGrid-main {
    .MuiDataGrid-columnHeaderTitle {
      font-weight: 700; font-family: $fontmontserrat; font-size: 15px;
    }
    .MuiDataGrid-sortIcon { opacity: 1 !important; }
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders { background-color: #F1F1F1; color: #494949; }
  .user-info-link { cursor: pointer; color: #212A42; font-family: $fontmontserrat; font-weight: 600; font-size: 14px; /* text-transform: capitalize; */ text-decoration: underline; }
  .user-info-link.member { font-weight: 400; }
  .user-details-link { cursor: pointer; color: #212A42; font-family: $fontmontserrat; text-decoration: underline; }
}