.assessment-page-v2 {
  .tab-container .tab-title { color: #57606f; }
  .custom-status { text-transform: capitalize; }
  .status-complete { color: green; }
  .status-pending { color: orange; }
  .custom-actions { display: flex; justify-content: space-evenly !important; gap: 10px; }
  .action-btn { color: #343333; }
  
  .overview-table {
    .overview-contents {
      .chart-contents {
       & { display: grid; grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); grid-gap: 20px;  margin-bottom: 30px; }
        .chart-section {
         & { display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 20px; height: 500px; background-color: #F1F1F1; position: relative; }
        }
        .chart-tools { position: absolute; top: 0; right: 0; visibility: hidden; z-index: 999; padding: 10px; }
        .chart-section:hover .chart-tools {  visibility: visible;   }
        .delete-icon,.edit-icon {  display: none; }
        .chart-section:hover .delete-icon, .chart-section:hover .edit-icon {  display: inline-block; cursor: pointer;  }
        .delete-icon,.edit-icon {  margin-right: 5px; }
        .chart-svg { z-index: 1;}
      }
      .question-matrix-section {
        & { display: grid; grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); grid-gap: 20px; height:auto; background-color: #F1F1F1; }
      }
      .add-chart {  display: flex; justify-content: center;  align-items: center; gap: 20px; cursor: pointer; }
    }
      
  }

  .header-tool-bar {
    & { display: flex; }
    .bulk-delete-btn { margin: -13px 0 0 15px; }
  }
}
